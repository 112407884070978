<template>
    <div class="main" :class="{active: isOpen}">
        <aside class="menu bg-dark">
            <ul class="p-0">
                <li class="float-end close">
                    <button class="btn btn-outline-danger" @click="isOpen = false"><i class="bi bi-x-lg"></i></button>
                </li>
                <li class="mt-5 mb-3 mx-2">
                    <img src="@/assets/admin_logo.png" height="48" width="48" /> <h4 class="m-0">ADMIN</h4>
                </li>
                <li class="m-1" @click="isOpen = false">
                    <router-link :to="{ name: 'AdminInbox' }" active-class="active" class="py-2">
                        INBOX
                    </router-link>
                </li>
                <li class="m-1" @click="isOpen = false">
                    <router-link :to="{ name: 'AdminAbout' }" active-class="active" class="py-2">
                        ABOUT
                    </router-link>
                </li>
                <li class="m-1" @click="isOpen = false">
                    <router-link :to="{ name: 'AdminTimeline' }" active-class="active" class="py-2">
                        TIMELINE
                    </router-link>
                </li>
                <li class="m-1" @click="isOpen = false">
                    <router-link :to="{ name: 'AdminWorks' }" active-class="active" class="py-2">
                        WORKS
                    </router-link>
                </li>
                <li class="m-1" @click="isOpen = false">
                    <router-link :to="{ name: 'AdminServices' }" active-class="active" class="py-2">
                        SERVICES
                    </router-link>
                </li>
            </ul>
        </aside>
        <div class="view">
            <nav class="navbar navbar-expand-lg navbar-light p-0">
                <div class="container-fluid">
                    <a class="navbar-brand" role="button" @click="isOpen = !isOpen">
                        <i class="fas bi" :class="{'fa-bars': !isOpen, 'bi-x-lg': isOpen}"></i>
                    </a>
                    <div class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle p-0" data-bs-toggle="dropdown" role="button">
                            <img src="@/assets/profile_pic.svg" height="32px" width="32px" />
                        </a>
                        <div class="dropdown-menu">
                            <a class="dropdown-item" role="button" @click="logout()">
                                <i class="fas fa-sign-out-alt"></i> LogOut
                            </a>
                        </div>
                    </div>
                </div>
            </nav>
            <router-view />
        </div>
    </div>
</template>

<script>

import firebase from '@/firebase'
export default {
    data() {
        return {
            isOpen: false,
            error: ''
        }
    },
    methods: {
        async logout() {
            try {
                await firebase.auth().signOut()
            } catch ({ code }) {
                this.error = code
            }
        }
    }
}
</script>

<style scoped>
.main {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.menu {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 0;
    transition: all .3s ease-in-out;
}

.menu ul li {
    display: none;
    color: #fff
}

.menu ul a {
    width: 100%;
    flex-direction: column;
    color: #fff;
    width: 100%;
    text-align: center;
    border-radius: 4px;
}

.menu ul a:hover {
    background-color: var(--bs-gray);
}

.menu ul a.active {
    background-color: var(--bs-primary);
}

.main.active .menu ul li {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.main.active .menu {
    width: 280px;
}

.view {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    transition: all .3s ease-in-out;
}

.dropdown-menu {
    margin-top: .4em;
    right: -.7em;
    left: auto !important;
    z-index: 5;
}
.close {
    position: absolute;
    top: 0;
    right: 0;
}

@media only screen and (min-width: 800px) {
    .main.active .view {
        width: calc(100% - 280px);
    }

    .close {
        display: none !important;
    }
}

@media only screen and (max-width: 800px) {
    .main.active .menu {
        position: fixed;
        width: 100%;
        z-index: 10;
    }
}
</style>